import React from 'react';
import { useNavigate } from 'react-router-dom';

import logo from 'assets/images/logo.png';

import './css/Header.css';

const Header: React.FC = () => {
    const navigate = useNavigate();

    return (
        <header>
            <img src={logo} alt="logo" />
            <div>
                <i className="icon icon-bell-o icon-30" onClick={() => navigate('/accounts/notice')}></i>
                <i className="icon icon-setting-o icon-22" onClick={() => navigate('/accounts/setting')}></i>
            </div>
        </header>
    );
};

export default Header;
